import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import { uiStore } from "@/libs/stores/ui.store";

const MenuItem = ({ to, icon, label, isActive }) => (
  <Link to={to} style={{ flex: 1 }}>
    <Flex
      direction="column"
      align="center"
      py={2}
      color={isActive ? "white" : "whiteAlpha.800"}
    >
      <FAIcon type={icon} fontSize="xl" mb={1} />
      <Text fontSize="xs">{label}</Text>
    </Flex>
  </Link>
);

const CreatorLayout = observer(() => {
  const location = useLocation();

  const menuItems = [
    { path: "/creator", icon: "dashboard", label: "儀表板" },
    { path: "/creator/streams", icon: "broadcast-tower", label: "直播" },
  ];

  return (
    <Box minH="100vh" bg="gray.900">
      {uiStore.showHeaderNav && (
        <>
          <Header px={6}>
            <Text color="white" fontSize="xl" fontWeight="bold">
              創作者中心
            </Text>
          </Header>

          <Box
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            bg="gray.900"
            borderTop="1px"
            borderColor="whiteAlpha.200"
          >
            <HStack spacing={0}>
              {menuItems.map(({ path, icon, label }) => (
                <MenuItem
                  key={path}
                  to={path}
                  icon={icon}
                  label={label}
                  isActive={location.pathname === path}
                />
              ))}
            </HStack>
          </Box>
        </>
      )}

      <Box flex="1" pb={uiStore.showHeaderNav ? 20 : 0}>
        <Outlet />
      </Box>
    </Box>
  );
});

export default CreatorLayout;
