import AuthContext from "@/context/auth";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useContext, useEffect } from "react";
import apiClient from "./api";

import { streamAPI, StreamData, subscribeToStreams } from "./api/stream";

// 查詢 keys 常數
export const queryKeys = {
  guestVideos: "guestVideos",
  creator: "creator",
  explore: "explore",
  gifts: "gifts",
  followedCreators: "followedCreators",
  videos: "videos",
  streams: "streams",
  stream: "stream",
};

// Guest Videos Query

// Creator Query
export const useCreator = (creatorId: string) => {
  return useQuery({
    queryKey: [queryKeys.creator, creatorId],
    queryFn: () => apiClient.getCreator(creatorId),
    enabled: !!creatorId,
  });
};

// Follow/Unfollow Mutations
export const useFollowCreator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (creatorId: string) => apiClient.followCreator(creatorId),
    onSuccess: (_, creatorId) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.creator, creatorId],
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.followedCreators] });
    },
  });
};

export const useUnfollowCreator = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (creatorId: string) => apiClient.unfollowCreator(creatorId),
    onSuccess: (_, creatorId) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.creator, creatorId],
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.followedCreators] });
    },
  });
};

// Explore Query
export const useExplore = () => {
  return useQuery({
    queryKey: [queryKeys.explore],
    queryFn: () => apiClient.getExplore(),
  });
};

// 獲取單個直播資訊
export const useStream = (streamId: string) => {
  return useQuery({
    queryKey: [queryKeys.stream, streamId],
    enabled: !!streamId,
  });
};

// 獲取所有進行中的直播
export const useLiveStreams = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const unsubscribe = subscribeToStreams(queryClient);
    return () => unsubscribe();
  }, [queryClient]);

  return useQuery({
    queryKey: [queryKeys.streams, "live"],
    enabled: true,
  });
};

// 開始直播
export const useStartStream = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<StreamData>) => streamAPI.startStream(data),
    onSuccess: (data) => {
      console.log("data", data);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.streams, "live"],
      });
      queryClient.setQueryData([queryKeys.stream, data.id], data);
    },
  });
};

// 結束直播
export const useEndStream = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (streamId: string) => streamAPI.endStream(streamId),
    onSuccess: (_, streamId) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.streams, "live"],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.stream, streamId],
      });
    },
  });
};

// 更新直播資訊
export const useUpdateStream = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      streamId,
      data,
    }: {
      streamId: string;
      data: Partial<StreamData>;
    }) => streamAPI.updateStream(streamId, data),
    onSuccess: (_, { streamId }) => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.stream, streamId],
      });
    },
  });
};

export const useDonateRanking = (streamId: string) => {
  return useQuery({
    queryKey: ["donate-ranking", streamId],
    queryFn: async () => {
      // Mock data for donate ranking
      return {
        data: [
          { id: "user1", name: "User 1", picture: "", total: 100 },
          { id: "user2", name: "User 2", picture: "", total: 50 },
        ],
        currentUser: { total: 20 },
      };
    },
    enabled: !!streamId,
    refetchInterval: 15000, // Refetch every 15 seconds
  });
};

// Videos Feed Query
interface VideoFeedResponse {
  data: Array<{
    id: string;
    creator: {
      id: string;
    };
  }>;
  paginator: {
    next: string | null;
  };
}

// Creator Videos Query
export const useCreatorVideos = (
  creatorId: string,
  initialVideoId?: string,
) => {
  return useInfiniteQuery({
    queryKey: [queryKeys.creator, creatorId, "videos"],
    queryFn: ({ pageParam }) =>
      apiClient.getCreatorVideos(creatorId, pageParam as string),
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.length && lastPage.paginator?.next !== null) {
        return lastPage.data[lastPage.data.length - 1].id;
      }
      return undefined;
    },
    initialPageParam: initialVideoId || null,
    enabled: !!creatorId,
    refetchOnWindowFocus: false,
  });
};

export const useVideosFeed = (id?: string) => {
  const { user } = useContext(AuthContext);
  return useInfiniteQuery<VideoFeedResponse, Error>({
    queryKey: [queryKeys.videos, !!user],
    queryFn: ({ pageParam }) => {
      return user
        ? apiClient.getVideosFeed(pageParam as string)
        : apiClient.getGuestVideos(pageParam as string);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.length && lastPage.paginator?.next !== null) {
        return lastPage.data[lastPage.data.length - 1].id;
      }
      return undefined;
    },
    initialPageParam: id || null,
    enabled: true,
    // staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
  });
};
