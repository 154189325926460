import { Box, Circle, Flex, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import FAIcon from "./FAIcon";
import { useCallback, useContext, useMemo } from "react";
import AuthContext from "@/context/auth";
import { useTranslation } from "react-i18next";
import useDeviceInfo from "@/libs/hooks/device-info";
import NotificationContext from "@/context/notification";
import { chatroomStore } from "@/libs/stores/chatroom.store";
import { observer } from "mobx-react-lite";

const MOBILE_NAVIGATION_HEIGHT = 80;
const MOBILE_NAVIGATION_Z_INDEX = 1700;

const NavigationButton = ({
  id,
  url,
  startsWith,
  badge,
  notice,
  icon,
  iconType = "regular",
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchedPath = url === pathname || pathname.startsWith(startsWith);
  return (
    <Box
      id={id}
      role="button"
      onClick={() => navigate(url)}
      color={matchedPath ? "secondary.100" : "white"}
      position="relative"
    >
      <FAIcon fontSize="1.5rem" variant={iconType} type={icon} />
      {badge && (
        <Circle
          right={-3}
          top={-3}
          position="absolute"
          bg="#EE3D52"
          size={6}
          fontSize="xs"
        >
          {badge}
        </Circle>
      )}
      {notice && (
        <Circle right={-1} top={-1} position="absolute" bg="#EE3D52" size={2}>
          {badge}
        </Circle>
      )}
    </Box>
  );
};

const MobileNavigation = () => {
  const { t } = useTranslation();

  const { chat } = chatroomStore.allChatrooms;
  const { user } = useContext(AuthContext);
  const { notice: notificationNotice } = useContext(NotificationContext);
  const { isMobile, isIPhone } = useDeviceInfo();
  const toast = useToast();

  const navigate = useNavigate();
  const totalUnread = chatroomStore.unreadCount;

  const unreadMessages = useMemo(() => {
    if (totalUnread === 0) return null;
    return totalUnread > 99 ? "99+" : totalUnread;
  }, [totalUnread]);

  const onCTA = useCallback(async () => {
    return navigate("/auth/register");
  }, [isMobile, navigate, toast]);

  return (
    <Box
      id="app-navigation"
      position="fixed"
      bottom={0}
      hidden={!!chat}
      width="100vw"
      display={{ base: "block", md: "none" }}
      height={`${MOBILE_NAVIGATION_HEIGHT}px`}
      zIndex={MOBILE_NAVIGATION_Z_INDEX}
      bg="primary.100"
      pb={isIPhone ? 2 : 0}
    >
      {!user && (
        <Flex
          position="absolute"
          height="100%"
          width="100%"
          align="center"
          justify="center"
          color="white"
          fontSize="xl"
          gap={3}
          zIndex={1}
          bg="rgba(0,0,0,.8)"
          pb={isIPhone ? 2 : 0}
          onClick={onCTA}
        >
          <FAIcon type="lock" variant="regular" />
          <Box role="button" onClick={onCTA} textDecor="underline">
            {isMobile && t("navigation.register_cta")}
          </Box>
          <FAIcon type="lock" variant="regular" />
        </Flex>
      )}
      <Flex
        left={0}
        gap={2}
        display={{ base: "flex", md: "none" }}
        height="100%"
        color="white"
        align="center"
        justify="space-between"
        px={8}
      >
        <NavigationButton
          id="button__navigation_index"
          url="/"
          startsWith="/videos"
          icon="film"
        />
        <NavigationButton
          id="button__navigation_streams"
          url="/streams"
          startsWith="/streams"
          icon="video"
        />
        <NavigationButton
          id="button__navigation_explore"
          url="/explore"
          icon="compass"
        />
        <NavigationButton
          id="button__navigation_chats"
          url="/chats"
          icon="comments"
          badge={unreadMessages}
        />
        <NavigationButton
          id="button__navigation_notifications"
          url="/notifications"
          icon="bell"
          notice={notificationNotice}
        />
        <NavigationButton
          id="button__navigation_profile"
          url="/profile"
          icon="user"
        />
      </Flex>
    </Box>
  );
};
MobileNavigation.HEIGHT = MOBILE_NAVIGATION_HEIGHT;
MobileNavigation.Z_INDEX = MOBILE_NAVIGATION_Z_INDEX;
export default observer(MobileNavigation);
