import { collection, query, where, limit, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useFirebase } from "./firebase";

export const useIsCreator = (userId) => {
  const [isCreator, setIsCreator] = useState(false);
  const [loading, setLoading] = useState(true);
  const { firestore } = useFirebase();

  useEffect(() => {
    if (!userId) {
      setIsCreator(false);
      setLoading(false);
      return;
    }

    const checkCreatorStatus = async () => {
      try {
        const creatorsRef = collection(firestore, "creators");
        const q = query(creatorsRef, where("userId", "==", userId), limit(1));
        const snapshot = await getDocs(q);
        setIsCreator(!snapshot.empty);
      } catch (error) {
        console.error("Error checking creator status:", error);
        setIsCreator(false);
      } finally {
        setLoading(false);
      }
    };

    checkCreatorStatus();
  }, [userId, firestore]);

  return { isCreator, loading };
};
