import { playerStore } from "@/libs/stores/player.store";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import Playlist from "@/components/Playlist";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { useVideosFeed } from "@/libs/queries";

const PlaylistPage = () => {
  const location = useLocation();
  const doneRef = useRef(false);
  const [initialized, setInitialized] = useState(false);

  const videoId =
    playerStore.getActivePlayer()?.id ?? location.pathname.split("/")[2];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useVideosFeed(videoId);

  // **初始化播放列表 (不觸發 fetchNextPage)**
  const handleInitVideos = useCallback(() => {
    if (initialized) return;
    const currentPage = data?.pages?.[data.pages.length - 1];
    if (!currentPage?.data?.length) return;

    const videos = currentPage.data.map((video) => ({
      ...video,
      creatorId: video.creatorId ?? video.creator.id,
    }));

    for (const video of videos) {
      playerStore.inQueue(video);
    }
    setInitialized(true);
    playerStore.openPlayList(); // 開啟播放列表
  }, [initialized, data]);

  // **加載新影片**
  const loadMoreVideos = useCallback(async () => {
    if (doneRef.current || isFetchingNextPage) return;

    const currentPage = data?.pages?.[data.pages.length - 1];
    if (!currentPage?.data?.length) return;

    const videos = currentPage.data.map((video) => ({
      ...video,
      creatorId: video.creatorId ?? video.creator.id,
    }));

    for (const video of videos) {
      playerStore.inQueue(video);
    }

    if (hasNextPage) {
      console.log("fetchNextPage");
      await fetchNextPage();
    } else {
      doneRef.current = true;
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, data]);

  // **監聽數據變化，確保播放器初始化 (只執行一次)**
  useEffect(() => {
    handleInitVideos();
  }, [handleInitVideos]);

  return (
    <Box position="absolute" top={0} left={{ base: 0, md: 320 }} right={0}>
      {playerStore.active && (
        <Playlist loader={loadMoreVideos} done={doneRef.current} />
      )}
    </Box>
  );
};

export default observer(PlaylistPage);
