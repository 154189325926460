import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { uiStore } from "@/libs/stores/ui.store";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Box, useToast } from "@chakra-ui/react";
import { useEndStream, useStartStream, useUpdateStream } from "@/libs/queries";
import { useAgoraClient } from "@/libs/hooks/useAgoraClient";
import CreatorStreamControls from "@/components/Stream/CreatorStreamControls";
import StreamSettings from "@/components/Stream/StreamSettings";

// AgoraRTC.enableLogUpload();

const CreatorStreamPage = observer(() => {
  const [localTracks, setLocalTracks] = useState({
    videoTrack: null,
    audioTrack: null,
  });
  const [isPreview, setIsPreview] = useState(true);
  const { data: agoraClient } = useAgoraClient();
  const [title, setTitle] = useState("");
  const [role] = useState("host");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [messagePrice, setMessagePrice] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const localVideoRef = useRef(null);
  const toast = useToast();
  const { mutateAsync: startStream } = useStartStream();
  const { mutateAsync: endStream } = useEndStream();
  const { mutateAsync: updateStream } = useUpdateStream();
  const [currentStreamId, setCurrentStreamId] = useState(null);
  const [isJoining, setIsJoining] = useState(false);
  const [viewerCount, setViewerCount] = useState(0);
  const [streamDuration, setStreamDuration] = useState(0);

  useEffect(() => {
    let timer;
    if (localTracks.videoTrack && !isPreview) {
      timer = setInterval(() => {
        setStreamDuration((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [localTracks.videoTrack, isPreview]);

  useEffect(() => {
    console.log("CurrentStreamId changed to:", currentStreamId);
  }, [currentStreamId]);

  useEffect(() => {
    if (!agoraClient) return;

    agoraClient.on("user-published", async (user, mediaType) => {
      await agoraClient.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteContainer = document.getElementById("remoteContainer");
        if (!remoteContainer) return;

        const playerContainer = document.createElement("div");
        playerContainer.id = `player-${user.uid}`;
        playerContainer.style.width = "240px";
        playerContainer.style.height = "135px";
        playerContainer.style.background = "#000";
        playerContainer.style.borderRadius = "8px";
        playerContainer.style.overflow = "hidden";
        playerContainer.style.margin = "8px";
        remoteContainer.appendChild(playerContainer);

        user.videoTrack.play(playerContainer);
      }
      if (mediaType === "audio") {
        user.audioTrack?.play();
      }
    });

    return () => {
      if (agoraClient) {
        agoraClient.removeAllListeners();
        setViewerCount(0);
      }
    };
  }, []);

  const startPreview = async () => {
    try {
      const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      const videoTrack = await AgoraRTC.createCameraVideoTrack();

      videoTrack.play(localVideoRef.current);
      setLocalTracks({ audioTrack, videoTrack });
    } catch (error) {
      console.error("Failed to start preview:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const join = async () => {
    if (!localTracks.videoTrack || !localTracks.audioTrack) {
      toast({
        title: "Error",
        description: "請先開啟攝影機和麥克風",
        status: "error",
        duration: 3000,
      });
      return;
    }

    setIsJoining(true);
    try {
      const stream = await startStream({ title: title || " " });

      if (!stream || !stream.agoraRoomId) {
        throw new Error("Failed to create stream - invalid response");
      }

      console.log("Stream started:", stream);
      const roomId = stream.agoraRoomId;
      setCurrentStreamId(roomId.replace("stream_", ""));
      console.log("CurrentStreamId set to:", roomId);

      await agoraClient.setClientRole(role);
      await agoraClient.join(
        "4a314f4d7234472fbcfcfaebbbed79c0",
        roomId,
        stream.agoraToken || null,
        stream.creatorId || null,
      );

      if (role === "host") {
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        const videoTrack = await AgoraRTC.createCameraVideoTrack();

        videoTrack.play(localVideoRef.current);
        setLocalTracks({ audioTrack, videoTrack });
        await agoraClient.publish([audioTrack, videoTrack]);
      }

      toast({
        title: "成功",
        description: "直播已開始！",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      setIsJoining(false);
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const navigate = useNavigate();

  const leave = async () => {
    try {
      // Clean up tracks first
      Object.values(localTracks).forEach((track) => {
        if (track) {
          track.stop();
          track.close();
        }
      });
      setLocalTracks({ videoTrack: null, audioTrack: null });
      if (agoraClient) {
        await agoraClient.leave();
      }

      // Only try to end stream if we have an ID
      if (currentStreamId) {
        try {
          await endStream(currentStreamId);
          console.log("Stream ended:", currentStreamId);
        } catch (error) {
          console.warn("Error ending stream:", error);
        }
      }

      setCurrentStreamId(null);
      setIsJoining(false);
      setStreamDuration(0);

      toast({
        title: "成功",
        description: "直播已結束！",
        status: "success",
        duration: 3000,
      });

      // Navigate to profile page after ending stream
      navigate("/creator");
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    uiStore.setShowHeaderNav(false);
    return () => {
      uiStore.setShowHeaderNav(true);
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const initPreview = async () => {
      if (!mounted) return;

      if (!localTracks.videoTrack && !isJoining && isPreview) {
        try {
          await startPreview();
        } catch (error) {
          console.error("Preview failed:", error);
        }
      }
    };

    initPreview();

    return () => {
      mounted = false;
    };
  }, []); // Run once when component mounts

  return (
    <Box h="100vh" w="100vw" position="relative" overflow="hidden">
      <Box position="absolute" top={4} right={4} zIndex={1}>
        <Box
          id="remoteContainer"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap={2}
        />
      </Box>
      {/* Video Preview */}
      <Box ref={localVideoRef} w="100%" h="100%" bg="gray.900" />

      {/* Creator Stream Controls */}
      <CreatorStreamControls
        id={currentStreamId}
        duration={streamDuration}
        onLeave={leave}
        localTracks={localTracks}
        onOpenSettings={() => setIsSettingsOpen(true)}
        isPreview={isPreview}
        onStartStream={async () => {
          setIsPreview(false);
          await join();
        }}
      />
      {/* Settings Modal */}
      <StreamSettings
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        title={title}
        setTitle={setTitle}
        messagePrice={messagePrice}
        setMessagePrice={setMessagePrice}
        localTracks={localTracks}
        isMuted={isMuted}
        setIsMuted={setIsMuted}
        isVideoEnabled={isVideoEnabled}
        setIsVideoEnabled={setIsVideoEnabled}
      />
    </Box>
  );
});

export default CreatorStreamPage;
